import React 		from 'react'
import classnames 	from 'classnames'
import { connect } 	from 'react-redux'

import ARYImage 		from '../../ary-image'
import CTAButtons 		from '../../cta-buttons'
import {
	ImageGalleryAuto,
	ScrollImageGalleryAuto
} from '../../image-gallery/auto'
import Toggle 			from '../../toggle'

import {
	FixedTextEl,
    ScrollTextEl
} from '../text-el'
import SectionWrapper 	from '../wrapper'

import {
	openOverlay
} from '../../../redux/actions'

import breakpoints 	from '../../../lib/constants/breakpoints.json'

import {
	renderHTML
} from '../../../lib/_helpers'

class SectionLanding extends React.Component {
	constructor(props) {
		super(props)

		this.openVideo = this.openVideo.bind(this)
	}

	openVideo(){
		const {
			dispatch
		} = this.props

		const openVideo = {
			src: 'https://array-supply.s3.amazonaws.com/kiss/KH_Film_FINAL.jpg',
            videoSrc: [
                { type: 'video/mp4', src: 'https://array-supply.s3.amazonaws.com/kiss/KH_Film_FINAL.mp4' },
                { type: 'video/webm', src: 'https://array-supply.s3.amazonaws.com/kiss/KH_Film_FINAL.webm' }
            ],
			width: 1920,
			height: 1080
		}

		dispatch(openOverlay({
			type: 'video', 
			image: openVideo
		}))
	}

	render() {
		const {
			dispatch,
			title,
			body,
			scalePerc,
			fixed,
			isActive,
			showIndicator,
			scrollPxInView
		} = this.props

		const TextElComponent = fixed ? FixedTextEl : ScrollTextEl

		const video = {
			src: 'https://array-supply.s3.amazonaws.com/kiss/1.jpg',
            videoSrc: [
                { type: 'video/mp4', src: 'https://array-supply.s3.amazonaws.com/kiss/1.mp4' },
                { type: 'video/webm', src: 'https://array-supply.s3.amazonaws.com/kiss/1.webm' }
            ],
			width: 1920,
			height: 1080
		}

		return <SectionWrapper
			className="section--landing section--c-dark"
			ignoreHeader={ true }
			headerDark={ true }
			fixed={ fixed }
			bgColour="greenDark"
			showIndicator={ showIndicator }
			scrollPx={ this.props.scrollPx }
			indexIsActive={ this.props.indexIsActive }>
			<div className="section-layer section-layer--bg">
				<ARYImage
					image={ video }
					videoOpts={{
						muted: true,
						autoplay: true
					}}
					shouldPlay={ isActive } />
			</div>
			<div className="section-layer section-layer--content section-el--centred">
				<div className="section-content">
					<div className="cont">
						<div className="sectionSpacer" />
						<TextElComponent
							isActive={ isActive }
							activeMult={ 0.7 }
							titleEl="h1Secondary"
							title={ title }
							body={ body }
							checkBottomForActive={ true } />
						<div className="sectionSpacer sectionSpacer--half" />
						<CTAButtons
							ctas={ [
								{
									label: 'Play video',
									onClick: this.openVideo
								}
							] } 
							colour="white"
							large={ true } />
						<div className="sectionSpacer" />
					</div>
				</div>
			</div>
		</SectionWrapper>
	}
}

export default connect()(SectionLanding)