import React 			    from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import {
    isFinite,
    isFunction
} from 'lodash'

import ScrollInViewport     from '../scroll-in-viewport'

import ImageGallery         from './gallery'

class ImageGalleryAuto extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            activeIndex: 0
        }
    }

    componentDidMount(){
        if(this.props.scrollInView){
            this.setTick()
        }
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.scrollInView !== this.props.scrollInView){
            if(this.props.scrollInView){
                this.setTick()
            } else {
                this.clearTick()
            }
        }
    }

    componentWillUnmount(){
        this.clearTick()
    }

    setTick(){
        const {
            time
        } = this.props
        this.clearTick()

        this.tickTO = setTimeout(() => {
            this.tick()
            this.setTick()
        }, isFinite(time) ? time : 3000)
    }

    tick(){
        const {
            activeIndex
        } = this.state
        const {
            images,
            onIndexChange
        } = this.props

        let newActiveIndex = activeIndex + 1
        if(newActiveIndex >= images.length){
            newActiveIndex = 0
        }

        this.setState({
            activeIndex: newActiveIndex
        })

        if(onIndexChange && isFunction(onIndexChange)){
            onIndexChange(newActiveIndex)
        }
    }

    clearTick(){
        if(this.tickTO){ clearTimeout(this.tickTO) }
    }

    render() {
        const {
            activeIndex
        } = this.state

        return <ImageGallery
            activeIndex={ activeIndex }
            { ...this.props } />
    }
}

const ScrollImageGalleryAuto = ScrollInViewport(ImageGalleryAuto)

export {
    ImageGalleryAuto,
    ScrollImageGalleryAuto
}