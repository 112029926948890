import Contact 				from './contact'
import Houses 				from './houses'
import Image 				from './image'
import ImageScroll 			from './image-scroll'
import Landing 				from './landing'
import SplitIllustration 	from './split-illustration'
import StoryCarousel 		from './story-carousel'
import Text 				from './text'

export const SectionContact = Contact
export const SectionHouses = Houses
export const SectionImage = Image
export const SectionImageScroll = ImageScroll
export const SectionLanding = Landing
export const SectionSplitIllustration = SplitIllustration
export const SectionStoryCarousel = StoryCarousel
export const SectionText = Text