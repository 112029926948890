import React 			from 'react'
import classnames 		from 'classnames'

import LinkWrapper 		from '../link-wrapper'

const links = [
	{
		type: 'twitter',
		link: 'https://twitter.com/TheKissHouse',
		handle: '@TheKissHouse'
	},
	{
		type: 'instagram',
		link: 'https://www.instagram.com/thekisshouse/',
		handle: '@thekisshouse'
	},
	{
		type: 'pinterest',
		link: 'https://www.pinterest.co.uk/TheKissHouse/',
		handle: '@TheKissHouse'
	},
	{
		type: 'facebook',
		link: 'https://www.facebook.com/TheKissHouseOfficial/',
		handle: '@TheKissHouseOfficial'
	},
	{
		type: 'medium',
		link: 'https://medium.com/@thekisshouse',
		handle: '@thekisshouse'
	}
]

export default (props) => {
	return <div className="socialIcons">
		{ links.map((_link, _i) => {
			return <LinkWrapper
				key={ _i }
				to={ _link.link }
				external={ true }
				className={ classnames('socialIcons-icon', {
					[`socialIcons-icon--${ _link.type }`]: _link.type
				}) } />
		}) }
	</div>
}