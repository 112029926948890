import React 		from 'react'
import classnames 	from 'classnames'
import { connect } 	from 'react-redux'

import ARYImage 		from '../../ary-image'

import SectionWrapper 	from '../wrapper'

import breakpoints 	from '../../../lib/constants/breakpoints.json'

class SectionImage extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {
			image,
			title,
			fixed
		} = this.props

		return <SectionWrapper
			className="section--image section--c-dark"
			ignoreHeader={ true }
			headerDark={ true }
			fixed={ fixed }
			scrollPx={ this.props.scrollPx }
			indexIsActive={ this.props.indexIsActive }>
			<div className="section-layer section-layer--bg">
				{ image ? <ARYImage image={ image } /> : null }
			</div>
		</SectionWrapper>
	}
}

export default SectionImage