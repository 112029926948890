import React 		from 'react'
import classnames 	from 'classnames'
import { connect } 	from 'react-redux'

import {
	FixedTextEl,
    ScrollTextEl
} from '../text-el'
import SectionWrapper 	from '../wrapper'

import breakpoints 	from '../../../lib/constants/breakpoints.json'

import {
	bgColourIsDark,
	renderHTML
} from '../../../lib/_helpers'

class SectionText extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const {
			title,
			body,
			bodyStyling,
			bgColour,
			hasFooter,
			fadeWait,
			fixed,
			isActive,
			className,
			headerBgColour,
			showIndicator,
			elID
		} = this.props

		const isDark = bgColour ? bgColourIsDark(bgColour) : false
		const TextElComponent = fixed ? FixedTextEl : ScrollTextEl

		return <SectionWrapper
			elID={ elID }
			bgColour={ bgColour }
			className={ classnames('section--text section-el--centred', {
				[`bg--${ bgColour }`]: bgColour,
				'txt--white': isDark
			}, className) }
			ignoreHeader={ isDark }
			headerDark={ isDark }
			headerBgColour={ headerBgColour && isDark ? true : false }
			fixed={ fixed }
			showIndicator={ showIndicator }
			scrollPx={ this.props.scrollPx }
			indexIsActive={ this.props.indexIsActive }>
			<div className="section-content">
				<div className="cont">
					<div className={ classnames('sectionSpacer', {
						'sectionSpacer--double': !!fixed
					}) } />
					<TextElComponent
						isActive={ isActive }
						wait={ fadeWait }
						title={ title }
						body={ body }
						bodyStyling={ bodyStyling } />
					<div className={ classnames('sectionSpacer', {
						'sectionSpacer--double': !!hasFooter
					}) } />
				</div>
			</div>
		</SectionWrapper>
	}
}

export default SectionText