import React 		from 'react'
import classnames 	from 'classnames'
import { connect } 	from 'react-redux'
import requestFrame 	from 'request-frame'
import {
	mapRange
} from 'canvas-sketch-util/math'
import {
	Link
} from 'gatsby'
import {
	isFunction
} from 'lodash'
import Measure 		from 'react-measure'

import {
	FixedSection,
	FixedSectionHOC
} from '../fixed-section'
import {
	FixedTextEl,
    ScrollTextEl
} from '../text-el'
import SectionWrapper 	from '../wrapper'

import ARYImage 		from '../../ary-image'
import CTAButtons 		from '../../cta-buttons'
import InlineImage 		from '../../inline-image'

import breakpoints 	from '../../../lib/constants/breakpoints.json'
import {
	renderHTML
} from '../../../lib/_helpers'
import {
	delayedVariable
} from '../../../lib/mathHelpers'

class SectionSplitIllustration extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			splitHeights: []
		}
	}

	onMeasureChange(dimensions, index) {
		const {
			splitHeights
		} = this.state

		splitHeights[index] = dimensions.client.height

		this.setState({
			splitHeights: splitHeights
		})
	}

	render() {
		const {
			splitHeights
		} = this.state
		const {
			fixed,
			isActive,
			splits,
			titleEl,
			additionalSpace,
			screenSize
		} = this.props

		const TextElComponent = fixed ? FixedTextEl : ScrollTextEl
		const twoSplits = splits && splits.length > 0 ? splits.slice(0, 2) : []

		const isMobile = screenSize.width <= breakpoints.tabletPortrait

		return <FixedSection
			{ ...this.props }
			naturalHeight={ isMobile }
			className={ classnames('section--splitIllustration', {
				'section--splitIllustration--stacked': isMobile
			}) }
			bgEl={ isMobile ? null : <div className="splitIllustration-wrapper splitIllustration-wrapper--bg">
					{ twoSplits ? twoSplits.map((_split, _i) => {
						return <div
							className="splitIllustration-split"
							key={ _i }>
							<div className="cont">
								<div className="splitIllustration-split-spacer"
									style={{
										height: isFinite(splitHeights[_i]) ? splitHeights[_i] : null
									}} />
								{ _split.image ? <div className="splitIllustration-split-image">
									<ARYImage
										contain={ true }
										imageAlignment={ _split.imageAlignment ? _split.imageAlignment : null }
										image={ _split.image } />
								</div> : null }
							</div>
						</div>
					}) : null }
				</div>
			}
			contentEl={
				<div className="section-content splitIllustration-wrapper splitIllustration-wrapper--content">
					{ twoSplits ? twoSplits.map((_split, _i) => {
						return <div
							className="splitIllustration-split"
							key={ _i }>
							<Measure client onResize={ (dimensions) => {
									this.onMeasureChange(dimensions, _i)
								} }>
								{({ measureRef }) => {
									return <div
										className="cont"
										ref={ measureRef }
										>
										{ isMobile && _i === 0 || additionalSpace ? <div className="sectionSpacer" /> : null }
										<div className={ classnames('sectionSpacer', {
											'sectionSpacer--half': titleEl && titleEl === 'h3'
										}) } />
										<TextElComponent
											isActive={ isActive }
											className={ classnames('txt--' + _split.colour )}
											activeMult={ 1 }
											title={ _split.title }
											titleEl={ titleEl ? titleEl : null }
											body={ _split.body }>
											{ _split.ctas ? <CTAButtons
												ctas={ _split.ctas }
												colour={ _split.colour }
												/> : null }
										</TextElComponent>
										<div className="sectionSpacer sectionSpacer--half" />
										{ isMobile && _split.image ? <InlineImage
													image={ _split.image } /> : null }
									</div>
								} }
							</Measure>
						</div>
					}) : null }
				</div>
			}
			fgEl={ isMobile ? null : <div className="splitIllustration-wrapper splitIllustration-wrapper--fg">
					{ twoSplits ? twoSplits.map((_split, _i) => {
						return <div
							className="splitIllustration-split"
							key={ _i }>
							{ _split.link ? <Link to={ _split.link } className="section-ctaBar">
								<span>Take a look</span>
							</Link> : <div className="section-ctaBar"
								onClick={ _split.action && isFunction(_split.action) ? (e) => {
									e.preventDefault()
									e.stopPropagation()

									_split.action()
								} : null }>
								<span>Take a look</span>
							</div> }
						</div>
					}) : null }
				</div>
			} />
	}
}

const FixedSectionSplitIllustration = FixedSectionHOC(SectionSplitIllustration)

export default (props) => {
	return <FixedSectionSplitIllustration
		{ ...props }
		offset={ 0 }
		spaceTopMult={ 0 }
		spaceBottomMult={ 0 }
		/>
}