import React 		from 'react'
import classnames 	from 'classnames'
import { compose } 	from 'redux'
import { connect } 	from 'react-redux'
import requestFrame 	from 'request-frame'
import {
	mapRange
} from 'canvas-sketch-util/math'
import {
	StaticQuery,
	graphql,
	Link
} from 'gatsby'
import {
	get,
	isFunction
} from 'lodash'
import Measure 		from 'react-measure'

import {
	FixedSection,
	FixedSectionHOC
} from '../fixed-section'
import {
	FixedTextEl,
    ScrollTextEl
} from '../text-el'
import SectionWrapper 	from '../wrapper'

import ARYImage 		from '../../ary-image'
import CTAButtons 		from '../../cta-buttons'
import InlineImage 		from '../../inline-image'
import {
    ImageGallery
} from '../../image-gallery'

import breakpoints 	from '../../../lib/constants/breakpoints.json'
import {
	formatSvgAndImage,
	renderHTML
} from '../../../lib/_helpers'
import {
	FileFragment,
	HouseElevationImageFragment
} from '../../../lib/fragments'
import {
	delayedVariable
} from '../../../lib/mathHelpers'

import {
	housesFromData,
	bedroomsFromData,
	housesForActiveBedroom
} from '../../houses-overlay/_helpers'

import {
	openOverlay
} from '../../../redux/actions'

class SectionHouses extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			activeIndex: 0
		}
	}

	componentDidMount(){
		this.playToggle()
	}

	componentWillUnmount(){
		this.clearTO()
	}

	componentDidUpdate(prevProps) {
		const {
			fixed,
			scrollInView,
			scrollPxInView
		} = this.props
		
		if(
			(fixed && prevProps.scrollPxInView !== scrollPxInView) ||
			(!fixed && prevProps.scrollInView !== scrollInView)
		){
			this.playToggle()
		}
	}

	playToggle(){
		const {
			fixed,
			scrollInView,
			scrollPxInView
		} = this.props
		
		if((fixed && scrollPxInView) || (!fixed && scrollInView)){
			this.setTO()
		} else {
			this.clearTO()
		}
	}

	clearTO(){
		if(this.tickTO){ clearTimeout(this.tickTO) }
	}

	setTO(){
		this.clearTO()
		return
		this.tickTO = setTimeout(() => {
			this.tickIndex()
			this.setTO()
		}, 2400)
	}

	tickIndex(){
		const {
			activeIndex
		} = this.state
		const {
			images
		} = this.props

		let i = activeIndex + 1
		if(i >= images.length){
			i = 0
		}

		this.setState({
			activeIndex: i
		})
	}

	render() {
		const {
			activeIndex
		} = this.state
		const {
			title,
			dispatch,
			isActive,
			images,
			fixed
		} = this.props

		const TextElComponent = fixed ? FixedTextEl : ScrollTextEl

		return <FixedSection
			{ ...this.props }
			className="section--houses section--ctaBar-active"
			bgEl={ null }
			contentEl={
				<div className="houseArea">
					{ !fixed ? <div className="sectionSpacer" /> : null }
					<div className="sectionSpacer sectionSpacer--half" />
					{ title ? <TextElComponent
						isActive={ isActive }
						activeMult={ 0.3 }
						titleEl="h3"
						title={ title } /> : null }
					<div className="sectionSpacer sectionSpacer--half" />
					<div className="houseArea-house">
						<div className="houseArea-house-cont">
							{ images ? <ImageGallery
                                activeIndex={ activeIndex }
                                contain={ true }
                                toggleClassName="toggleWrapper--fadeInOut"
                                swapSvg={ true }
                                images={ images } /> : null }
						</div>
					</div>
					<div className="sectionSpacer" />
					<div className="sectionSpacer" />
				</div>
			}
			fgEl={
				<div
					onClick={ (e) => {
						e.preventDefault()
						e.stopPropagation()

						dispatch(openOverlay({type: 'houses'}))
					} }
					className="section-ctaBar">
					<span>Take a look</span>
				</div>
			} />
	}
}

const FixedSectionHouses = FixedSectionHOC(SectionHouses)

export default (props) => {
	return <StaticQuery
		query={ graphql`
			query {
				houses: allWordpressWpHouse {
					edges {
						node {
							wordpress_id
							bedrooms
							acf {
								elevation_images {
									image {
										...ARYImageHousesElevation
									}
									svg {
										...FileWPMedia
									}
									svg_ratio
								}
							}
						}
					}
				}
				bedrooms: allWordpressWpBedrooms {
					edges {
						node {
							name
							wordpress_id
						}
					}
				}
			}
		` }
		render={ (data) => { 
			const houses = housesFromData(data)
			const bedrooms = bedroomsFromData(data, houses)

			const images = bedrooms && houses ? bedrooms.map((_bedroom, _i) => {
				const _houses = housesForActiveBedroom(_bedroom.wordpress_id, houses)
				return formatSvgAndImage(get(_houses, '0.acf.elevation_images.0'))
			}) : null

			return <FixedSectionHouses
				data={ data }
				{ ...props }
				images={ images }
				offset={ 0 }
				spaceTopMult={ 0 }
				spaceBottomMult={ 0 }
				 />
		} } />
}