import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'

import { FixedTextEl, ScrollTextEl } from '../text-el'
import SectionWrapper from '../wrapper'

import CTAButtons from '../../cta-buttons'
import Newsletter from '../../newsletter'
import SocialIcons from '../../social-icons'

import breakpoints from '../../../lib/constants/breakpoints.json'
import { renderHTML } from '../../../lib/_helpers'

import { openOverlay } from '../../../redux/actions'

class SectionContact extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { dispatch, title, body, fixed, isActive, isLast } = this.props

    const TextElComponent = fixed ? FixedTextEl : ScrollTextEl

    return (
      <SectionWrapper
        className="section--contact section-el--centred"
        fixed={fixed}
        isLast={isLast}
        scrollPx={this.props.scrollPx}
        indexIsActive={this.props.indexIsActive}
      >
        <div className="section-content">
          <div className="cont">
            <div className="sectionSpacer sectionSpacer--double" />
            <TextElComponent
              sansStyling={true}
              isActive={isActive}
              title="We’d love to hear from you"
            >
              <p>
                Use our form to tell us about you, what you’d like to know & if
                you’d like a free site assessment
              </p>
              <CTAButtons
                fullWidth={true}
                ctas={[
                  {
                    label: 'Make an enquiry',
                    onClick: (e) => {
                      dispatch(
                        openOverlay({
                          type: 'enquiry',
                        })
                      )
                    },
                  },
                ]}
              />
              <p>For updates on the world of Kiss House</p>
              <Newsletter />
              <p>
                0118 3800171 or{' '}
                <a href="mailto:support@kisshouse.co.uk" target="_blank">
                  support@kisshouse.co.uk
                </a>
              </p>
              <p>
                Bowman House, 2-10 Bridge Street, <br />
                Reading, RG1 2LU, UK
              </p>
              <SocialIcons />
            </TextElComponent>
            <div className="sectionSpacer" />
          </div>
        </div>
      </SectionWrapper>
    )
  }
}

export default connect(null, null)(SectionContact)
