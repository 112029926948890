import React from 'react'
import classnames from 'classnames'
import { connect } from 'react-redux'
import requestFrame from 'request-frame'
import { mapRange } from 'canvas-sketch-util/math'
import { Link } from 'gatsby'

import { FixedSection, FixedSectionHOC } from '../fixed-section'
import { FixedTextEl, ScrollTextEl } from '../text-el'
import SectionWrapper from '../wrapper'

import InlineImage from '../../inline-image'

import breakpoints from '../../../lib/constants/breakpoints.json'
import { renderHTML } from '../../../lib/_helpers'
import { delayedVariable } from '../../../lib/mathHelpers'

class SectionStoryCarousel extends React.Component {
  constructor(props) {
    super(props)

    this.loopStep = this.loopStep.bind(this)

    this.speedMult = 1

    this.state = {
      scalePerc: 0,
      xPos: 0,
      isHovered: false,
      storiesPerScreen: 4,
      storiesMargin: 25,
      storiesSingleWidth: 100,
    }
  }

  componentDidMount() {
    this.updateStoriesSize()
    this.playToggle()
  }

  componentWillUnmount() {
    this.cancelLoop()
  }

  componentDidUpdate(prevProps) {
    const { fixed, scrollInView, scrollPxInView } = this.props

    if (
      prevProps.frameSize.width !== this.props.frameSize.width ||
      prevProps.stories.length !== this.props.stories.length
    ) {
      this.updateStoriesSize()
    }
    if (
      (fixed && prevProps.scrollPxInView !== scrollPxInView) ||
      (!fixed && prevProps.scrollInView !== scrollInView)
    ) {
      this.playToggle()
    }
  }

  playToggle() {
    const { fixed, scrollInView, scrollPxInView } = this.props

    if ((fixed && scrollPxInView) || (!fixed && scrollInView)) {
      this.nextLoop()
    } else {
      this.cancelLoop()
    }
  }

  nextLoop() {
    const request = requestFrame('request')
    this.loopStepLoopID = request(this.loopStep)
  }

  cancelLoop() {
    const cancel = requestFrame('cancel')
    if (this.loopStepLoopID) {
      cancel(this.loopStepLoopID)
    }
  }

  loopStep() {
    const { percScrolled, frameSize } = this.props
    const { xPos, storiesPerScreen, isHovered, storiesTotalWidth } = this.state

    let _xPos = xPos
    const speed = (frameSize.width / storiesPerScreen) * 0.003
    _xPos -= speed
    if (_xPos < storiesTotalWidth * -1) {
      _xPos = 0
    }

    this.setState({
      xPos: _xPos,
    })

    this.nextLoop()
  }

  updateStoriesSize() {
    const { storiesMargin } = this.state
    const { frameSize, screenSize, stories } = this.props

    let storiesPerScreen = 0
    if (screenSize.width <= breakpoints.mobilePortrait) {
      storiesPerScreen = 1.5
    } else if (screenSize.width <= breakpoints.mobile) {
      storiesPerScreen = 2
    } else if (screenSize.width <= breakpoints.tablet) {
      storiesPerScreen = 3
    } else {
      storiesPerScreen = 4
    }

    const storiesCount = stories.length
    const storiesSingleWidth = Math.round(
      (frameSize.width - storiesMargin * (storiesPerScreen - 1)) /
        storiesPerScreen
    )
    const storiesTotalWidth =
      (storiesSingleWidth + storiesMargin) * storiesCount
    const repeatedStoriesTotalWidth =
      (storiesSingleWidth + storiesMargin) * (storiesCount + storiesPerScreen)

    this.setState({
      storiesCount,
      storiesSingleWidth,
      storiesPerScreen,
      storiesTotalWidth,
      repeatedStoriesTotalWidth,
    })
  }

  carousel() {
    const {
      scalePerc,
      storiesPerScreen,
      storiesMargin,
      storiesSingleWidth,
      repeatedStoriesTotalWidth,
      xPos,
    } = this.state
    const { frameSize, stories } = this.props

    const translate = `translate3d(${xPos}px,0,0)`

    const repeatedStories = [...stories, ...stories.slice(0, storiesPerScreen)]

    return (
      <div
        className="storyCarousel-carousel"
        style={{
          width: repeatedStoriesTotalWidth,
          WebkitTransform: translate,
          transform: translate,
        }}
      >
        {repeatedStories.map((_story, _i) => {
          return (
            <div
              key={_i}
              className="storyCarousel-carousel-item"
              onMouseEnter={() => {
                this.setState({
                  isHovered: true,
                })
              }}
              onMouseLeave={() => {
                this.setState({
                  isHovered: false,
                })
              }}
              style={{
                width: storiesSingleWidth,
                marginRight: storiesMargin,
              }}
            >
              {_story.image ? <InlineImage image={_story.image} /> : null}
            </div>
          )
        })}
      </div>
    )
  }

  render() {
    const { title, body, fixed, isActive, link } = this.props

    const TextElComponent = fixed ? FixedTextEl : ScrollTextEl

    return (
      <FixedSection
        {...this.props}
        className="section--storyCarousel bg--purple section--c-dark"
        headerDark={true}
        bgEl={this.carousel()}
        contentEl={
          <div className="section-content">
            <div className="cont">
              <div className="sectionSpacer" />
              <TextElComponent
                isActive={isActive}
                activeMult={0.4}
                checkBottomForActive={true}
                title={title}
                body={body}
              />
              <div className="sectionSpacer sectionSpacer--double" />
            </div>
          </div>
        }
        fgEl={
          <Link to={link ? link : '/'} className="section-ctaBar">
            <span>Take a look</span>
          </Link>
        }
      />
    )
  }
}

const FixedSectionStoryCarousel = FixedSectionHOC(SectionStoryCarousel)

export default (props) => {
  return (
    <FixedSectionStoryCarousel
      {...props}
      spaceTopMult={0.25}
      spaceBottomMult={0}
    />
  )
}
