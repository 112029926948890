import React 		from 'react'
import classnames 	from 'classnames'
import { compose } 	from 'redux'
import { connect } 	from 'react-redux'
import {
	mapRange
} from 'canvas-sketch-util/math'
import requestFrame 	from 'request-frame'

import FixedScrollEl 	from '../fixed-scroll-el'
import ScrollIndicator 	from '../scroll-indicator'
import SectionWrapper 	from './wrapper'

import {
	renderHTML
} from '../../lib/_helpers'

function _FixedSectionHOC(WrappedComponent) {
	return class extends React.Component {
		frameSize(){
	    	const {
	    		offset,
	    		screenSize,
	    		fixed
	    	} = this.props

	    	return {
	    		width: screenSize.width,
	    		height: screenSize.height - (fixed ? 0 : offset)
	    	}
	    }

		render() {
			const {
				spaceTopMult,
				spaceBottomMult,
				fixed
			} = this.props

			const frameSize = this.frameSize()
			const singleHeight = frameSize.height

			const spaceTop = singleHeight * (isFinite(spaceTopMult) ? spaceTopMult : 0)
			const spaceBottom = singleHeight * (isFinite(spaceBottomMult) ? spaceBottomMult : 0)
			const totalHeight = spaceTop + singleHeight + spaceBottom

			return <WrappedComponent
				{ ...this.props }
				frameSize={ frameSize }
				singleHeight={ singleHeight }
				spaceTop={ spaceTop }
				spaceBottom={ spaceBottom }
				totalHeight={ totalHeight }
				/>
		}
	}
}

const mapStateToProps = (state) => {
	const {
		ui
	} = state

    return {
    	headerHeight: ui.header.height,
    	screenSize: ui.screenSize
    }
}

const FixedSectionHOC = compose(
	FixedScrollEl,
	connect(mapStateToProps, null),
	_FixedSectionHOC
)

const FixedSection = (props) => {
	const {
		className,
		bgEl,
		contentEl,
		fgEl,
		percScrolled,
		frameSize,
		spaceTop,
		spaceBottom,
		singleHeight,
		totalHeight,
		naturalHeight,
		contentCentred,
		fixedStyle,
		additionalFixedStyle,
		headerHeight,
		headerDark,
		showIndicator,
		ignoreScrollMonitor,
		fixed,
		isActive
	} = props

	const isNatural = naturalHeight && !fixed

	const fixedElStyle = fixed ? {
		height: singleHeight,
		...additionalFixedStyle
	} : {
		...fixedStyle,
		height: singleHeight,
		...additionalFixedStyle
	}

	return <SectionWrapper
			forceHeight={ totalHeight }
			naturalHeight={ isNatural }
			className={ classnames('section--fixed', className, {
				'section--ctaBar-active': (percScrolled >= 0.5) || (fixed && isActive)
			}) }
			ignoreHeader={ true }
			ignoreScrollMonitor={ ignoreScrollMonitor }
			headerDark={ headerDark }
			fixed={ fixed }
			showIndicator={ false }
			scrollPx={ props.scrollPx }
			indexIsActive={ props.indexIsActive }>
			{ bgEl ? <div className="section-layer section-layer--bg"
				style={ fixedElStyle }>
				{ bgEl }
				{ showIndicator ? <ScrollIndicator onDark={ true } /> : null }
			</div> : null }
			{ showIndicator ? <div className="section-layer section-layer--indicator"
				style={ {
					...fixedStyle,
					height: singleHeight
				} }>
				<ScrollIndicator />
			</div> : null }
			{ contentEl ? <div className={ classnames('section-layer section-layer--content section-layer--inline', {
					'section-el--centred': contentCentred
				}) }
				style={{
					paddingTop: fixed ? headerHeight : spaceTop,
					height: fixed ? singleHeight : (isNatural ? 'auto' : spaceTop + singleHeight)
				}}>
				{ contentEl }
			</div> : null }
			{ fgEl ? <div className="section-layer section-layer--fg"
				style={ fixedElStyle }>
				{ fgEl }
			</div> : null }
		</SectionWrapper>
}

export {
	FixedSection,
	FixedSectionHOC
}