import React 		from 'react'
import classnames 	from 'classnames'
import { connect } 	from 'react-redux'
import {
	mapRange
} from 'canvas-sketch-util/math'
import requestFrame 	from 'request-frame'
import PS 				from 'publish-subscribe-js'
import {
	Link
} from 'gatsby'
import TWEEN            from '@tweenjs/tween.js'

import ARYImage 		from '../../ary-image'
import FixedScrollEl 	from '../../fixed-scroll-el'
import {
	ImageGalleryAuto,
	ScrollImageGalleryAuto
} from '../../image-gallery/auto'

import {
	FixedSection,
	FixedSectionHOC
} from '../fixed-section'
import {
	FixedTextEl,
    ScrollTextEl
} from '../text-el'
import SectionWrapper 	from '../wrapper'

import {
	setHeaderColour,
	transitionHeaderColour
} from '../../../redux/actions'

import breakpoints 	from '../../../lib/constants/breakpoints.json'
import {
	renderHTML
} from '../../../lib/_helpers'
import {
	roundTo,
	delayedVariable,
	clipPathFromPath,
	pathFromRect,
	shouldShowPortrait,
	fitDimensionsToBox
} from '../../../lib/mathHelpers'
import {
	PAGE_INIT
} from '../../../lib/pubSubEvents'

class SectionImageScroll extends React.Component {
	constructor(props) {
		super(props)

		this.initCheck = this.initCheck.bind(this)
		this.loopStep = this.loopStep.bind(this)
		this.scalePerc = 0

		this.state = {
			scalePerc: 0,
			needsInit: !!(props.isActive && props.fixed),
			hasInit: false
		}
	}

	componentDidMount() {
		const {
			needsInit
		} = this.state

		this.tweens = new TWEEN.Group()
	    this.nextLoop()

	    this.pageInitKey = PS.subscribe(PAGE_INIT, this.initCheck)

	    this.setInitTO()
	}

	componentDidUpdate(prevProps, prevState){
		const {
			fixed,
			isActive,
			scrollPxInView,
			scrollPx
		} = this.props

		if(fixed){
			if(prevProps.isActive && !isActive){
				this.clearInitTO()
			} else if(isActive && prevProps.isActive !== isActive){
				this.animateScalePerc()	
			} else if(prevProps.scrollPxInView && !scrollPxInView){
				if(scrollPx < 0){
					this.resetAnimateScale(0)
				} else {
					this.resetAnimateScale(1)
				}
			}
		}
	}

	componentWillUnmount() {
		if(this.pageInitKey){ PS.unsubscribe(PAGE_INIT, this.initCheck) }

		this.clearInitTO()
		
		this.tweens.removeAll()
		this.cancelLoop()
	}

	setInitTO(){
		const {
			needsInit
		} = this.state

		this.clearInitTO()
		
		if(!needsInit){ return }

		this.initTO = setTimeout(() => {
			this.setState({
				hasInit: true
			})
			this.animateScalePerc()
		}, 1500)
	}

	clearInitTO(){
		if(this.initTO){ clearTimeout(this.initTO) }
	}

	initCheck(){
		const {
			hasInit,
			needsInit
		} = this.state

		if(needsInit && !hasInit){
			this.setInitTO()
		}
	}

	animateScalePerc(){
		const {
			delayTypeFade
		} = this.props

		this.tweens.removeAll()

		const tween = new TWEEN.Tween({
				i: this.scalePerc
			}, this.tweens)
			.to({
				i: 1
			}, 1500)
			.delay(delayTypeFade ? 500 : 0)
			.easing(TWEEN.Easing.Cubic.InOut)
			.onUpdate(({ i }) => {
				this.scalePerc = i
			})
			.onComplete(({ i }) => {
				this.scalePerc = i
			})
		tween.start()
	}

	resetAnimateScale(target = 0){
		this.tweens.removeAll()
		this.scalePerc = target
	}

	nextLoop(){
		const request = requestFrame('request')
        this.loopStepLoopID = request(this.loopStep)
	}

	cancelLoop(){
		const cancel = requestFrame('cancel')
		if(this.loopStepLoopID){ cancel(this.loopStepLoopID) }
	}

	loopStep(){
		const {
			dispatch,
			headerColour,
			headerHeight,
			percScrolled,
			percIn,
			scrollInView,
			scrollPxInView,
			fixed
		} = this.props
    	const {
    		scalePerc
    	} = this.state

    	this.tweens.update()

    	let _scalePerc = 0
    	if(fixed){
    		_scalePerc = this.scalePerc
    	} else {
	    	const mappedPercScrolled = mapRange(percScrolled, 0, 0.5, 0, 1, true)
	    	_scalePerc = delayedVariable(scalePerc, mappedPercScrolled, 0.15, 1000)
	    }

		if(scalePerc !== _scalePerc){
			this.setState({
				scalePerc: _scalePerc
			})

			if(
				(
					fixed && scrollPxInView ||
					!fixed && scrollInView
				)
			 	//&& _scalePerc >= 0.5
			){
				if(_scalePerc < 1){
					if(headerColour === 'dark'){
						dispatch(setHeaderColour('light'))
					}
					const amountTransitioned = roundTo(mapRange(_scalePerc, 0.5, 1, 0, headerHeight, true), 100)
					dispatch(transitionHeaderColour('dark', amountTransitioned))
				} else {
					dispatch(setHeaderColour('dark'))
				}
			}
		}
		
		this.nextLoop()
    }

    imageScale(cM, iB){
    	const {
			scalePerc,
    	} = this.state
    	const {
    		headerHeight,
    		frameSize
    	} = this.props

    	const cMHV = this.clipMarginHV(cM)
    	const scaleMarginMult = 2
		const scaleMinX = (iB.width - (cMHV.h * scaleMarginMult)) / frameSize.width
		const scaleMinY = (iB.height - (cMHV.v * scaleMarginMult)) / frameSize.height
		const scaleMin = Math.round(Math.min(scaleMinX, scaleMinY) * 100) / 100

		return `scale(${ mapRange(scalePerc, 0, 0.5, scaleMin, 1.05, true) })`
    }

    clipPath(cM){
    	const {
			scalePerc
    	} = this.state
    	const {
    		headerHeight,
    		frameSize
    	} = this.props

		if(scalePerc >= 1){
			return null
		}

		const topMargin = mapRange(scalePerc, 0.5, 1, headerHeight, 0, true)
		const clipMargin = mapRange(scalePerc, 0, 0.5, cM, 0, true)
		const cMHV = this.clipMarginHV(clipMargin)

		const rectFrom = [cMHV.h, topMargin + cMHV.v]
		const rectTo = [frameSize.width - cMHV.h, frameSize.height - cMHV.v]

		const path = pathFromRect(rectFrom, rectTo)
		
		return clipPathFromPath(path)
    }

    clipMarginHV(clipMargin){
    	const {
    		frameSize
    	} = this.props

    	const isPortrait = frameSize.width < frameSize.height

    	const clipMarginH = isPortrait ? 0 : clipMargin
    	const clipMarginV = isPortrait ? clipMargin * 4 : clipMargin

    	return {
    		h: clipMarginH,
    		v: clipMarginV
    	}
    }

    clipMargin(){
    	const {
    		headerHeight,
    		screenSize
    	} = this.props

    	if(screenSize.width <= breakpoints.mobilePortrait){
    		return 30
    	} else if(screenSize.width <= breakpoints.mobile){
    		return 40
    	} else {
    		return headerHeight
    	}
    }

    image(){
    	const {
    		image,
    		mobileImage,
    		screenSize
    	} = this.props

    	const isMobileImage = mobileImage && shouldShowPortrait(screenSize)

    	return isMobileImage ? mobileImage : image
    }

    renderImage(){
    	const {
    		image,
    		mobileImage,
    		screenSize
    	} = this.props

    	const isMobileImage = mobileImage && shouldShowPortrait(screenSize)

    	return <ARYImage
			key={ isMobileImage ? 'mobile' : 'desktop' }
			image={ this.image() } />
    }

	render() {
		const {
			scalePerc
		} = this.state
		const {
			title,
			link,
			body,
			image,
			mobileImage,
			fixedStyle,
			fixed,
			ignoreCTA,
			isActive,
			showIndicator,
			delayTypeFade,
			frameSize,
			screenSize
		} = this.props

		const isMobileImage = mobileImage && shouldShowPortrait(screenSize)
		const _image = isMobileImage ? mobileImage : image

		const clipMargin = this.clipMargin()
		const imageBox = fitDimensionsToBox(
			frameSize,
			{
				width: _image.width,
				height: _image.height,
				ratio: 1 / _image.aspectRatio
			}
		)

		const imageScale = this.imageScale(clipMargin, imageBox)
		const clipPath = this.clipPath(clipMargin)

		const TextElComponent = fixed ? FixedTextEl : ScrollTextEl

		return <FixedSection
			{ ...this.props }
			className="section--imageScroll section--c-dark"
			additionalFixedStyle={{
				WebkitClipPath: clipPath,
				clipPath: clipPath
			}}
			ignoreScrollMonitor={ scalePerc >= 0.5 && scalePerc < 1 }
			headerDark={ scalePerc >= 1 }
			contentCentred={ true }
			showIndicator={ showIndicator }
			bgEl={
				<React.Fragment>
					<div className="section-layer-image-tint"
						style={{
							opacity: scalePerc
						}} />
					{ image ? <div className="section-layer-image"
						style={{
							width: imageBox.width,
							height: imageBox.height,
							marginLeft: imageBox.width * -0.5,
							marginTop: imageBox.height * -0.5,
							WebkitTransform: imageScale,
							transform: imageScale
						}}>
						<ARYImage
							key={ isMobileImage ? 'mobile' : 'desktop' }
							image={ _image } />
						</div> : null }
				</React.Fragment>
			}
			contentEl={
				<div className="section-content">
					<div className="cont">
						<div className="sectionSpacer" />
						<TextElComponent
							isActive={ isActive && scalePerc > 0.5 }
							activeMult={ 0.7 }
							delayTypeFade={ fixed ? delayTypeFade : false }
							title={ title }
							body={ body }
							checkBottomForActive={ true } />
						<div className="sectionSpacer sectionSpacer--double" />
					</div>
				</div>
			}
			fgEl={
				!ignoreCTA ? <Link
					to={ link ? link : '/' }
					className="section-ctaBar">
					<span>Take a look</span>
				</Link> : null
			} />
	}
}

const mapStateToProps = (state) => {
	const {
		ui
	} = state

    return {
    	headerHeight: ui.header.height,
    	headerColour: ui.header.colour,
    	screenSize: ui.screenSize
    }
}

const FixedSectionImageScroll = FixedSectionHOC(connect(mapStateToProps, null)(SectionImageScroll))

export default (props) => {
	return <FixedSectionImageScroll
		{ ...props }
		offset={ 0 }
		spaceTopMult={ 1 }
		spaceBottomMult={ 0 }
		/>
}